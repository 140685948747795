import React from "react";
import { ThemeDarkName, ThemeLightName, SyncfusionThemeLightCss, SyncfusionThemeDarkCss } from "../data/global";

const useThemeService = () => {

    const [theme, setTheme] = React.useState(localStorage.theme || ThemeLightName);
    const [syncfusionCss, setSyncfusionCss] = React.useState();
    const [newTheme, setNewTheme] = React.useState(theme);

    const applyTheme = (newThemeName, setLocalStorageValue) => {

        setTheme(newThemeName);
        if (setLocalStorageValue){
            localStorage.setItem("theme",newThemeName);
        }
    };

    const handleStorageChange = React.useCallback(() => {

        applyTheme(getTheme(), false);

    },[]);

    const switchTheme = () => {

        const nuevoTheme = theme === ThemeDarkName ? ThemeLightName : ThemeDarkName;

        setNewTheme(nuevoTheme);

    };

    const getSyncfusionCssForTheme = () => {

        return getTheme() === ThemeDarkName
            ? SyncfusionThemeDarkCss
            : SyncfusionThemeLightCss;

    };

    const getTheme = () => {

        return localStorage.getItem("theme");

    };

    React.useEffect(() => {

        if (theme === ThemeDarkName) {
            document.documentElement.classList.add(ThemeDarkName);
            setSyncfusionCss(SyncfusionThemeDarkCss);
        } else {
            document.documentElement.classList.remove(ThemeDarkName);
            setSyncfusionCss(SyncfusionThemeLightCss);
        }

    }, [theme]);

    React.useEffect(() => {

        applyTheme(newTheme, true)

    }, [newTheme]);

    React.useEffect(() => {

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };

    }, [handleStorageChange]);

    return [{ theme, syncfusionCss, ThemeDarkName, ThemeLightName }, switchTheme, getSyncfusionCssForTheme];
};

export default useThemeService;