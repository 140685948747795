const DownloadFile = (url, method, contentType, filenameByDefault) => {

    return new Promise((resolve, reject) => {

        fetch(url, {
            method: method,
            headers: {
                'Content-Type': contentType
            }
        })
        .then(response => {
            // Verificar si la respuesta es exitosa
            if (!response.ok) {
                throw new Error('Error al descargar el archivo');
            }
            // Obtener el nombre del archivo del encabezado Content-Disposition
            const contentDisposition = response.headers.get('Content-Disposition');
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            let filename = filenameByDefault; // Nombre predeterminado si no se encuentra ninguno en el encabezado

            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }

            // Iniciar la descarga del archivo
            return response.blob().then(blob => ({ blob, filename }));
        })
        .then(({ blob, filename }) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename.trim());
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            reject(error);
        })
        .finally(() => {
            resolve("Archivo descargado");
        });

    });

}

export default DownloadFile;