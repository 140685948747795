import { classNames } from "../../utils"

function BoxContainer(props) {
    return (
        <div className={classNames(props.className ?? "","relative bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden")}>
            {props.children}
        </div>
    )
}

export { BoxContainer };