import React from 'react';
import { Menu } from '@headlessui/react'
import { ButtonSwitchTheme } from '../button';
import { NavLink } from 'react-router-dom';
import { NombreUsuario, EmailUsuario } from '../../data/datosLocalStorage';

const URL_LOGOUT = "/logout";

/// fix para que no patalee Menu.Item al tener un elemento como ButtonSwitchTheme
const BtnSwitchTheme = React.forwardRef((props, ref) => <div><ButtonSwitchTheme /></div>);

function UserAvatar() {
    return (
        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
    )
}

function TopMenuUserDesktop() {
    
    const [nombreUsuario] = React.useState(NombreUsuario);
    const [emailUsuario] = React.useState(EmailUsuario);

    return (
        <Menu as="div" className="ml-3 relative">
            <div>
                <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none">
                    <span className="sr-only">Menú Usuario</span>
                    <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                        <UserAvatar />
                    </span>
                    <div className="ml-3 text-start">
                        <div className="text-sm font-medium text-gray-800">{nombreUsuario}</div>
                        <div className="text-xs font-medium text-gray-500">{emailUsuario}</div>
                    </div>
                </Menu.Button>
            </div>
            <Menu.Items
                className="origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                    <BtnSwitchTheme />
                </Menu.Item>
                <Menu.Item>
                    <NavLink
                        to={URL_LOGOUT}
                        className={'hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700'}
                    >
                        Salir
                    </NavLink>
                </Menu.Item>
            </Menu.Items>
        </Menu>
    );
}

function TopMenuUserMobile() {

    const [nombreUsuario] = React.useState(NombreUsuario);
    const [emailUsuario] = React.useState(EmailUsuario);
    const [menuToggle, setMenuToggle] = React.useState(false);

    const toggleMenu = () => {

        setMenuToggle(!menuToggle);

        return true;
    };

    return (<>
        <div
            onClick={toggleMenu}
            className="flex items-center px-4">
            <div className="flex-shrink-0">
                <span
                    className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                    <UserAvatar />
                </span>
            </div>
            <div className="ml-3">
                <div className="text-base font-medium text-gray-800">{nombreUsuario}</div>
                <div className="text-sm font-medium text-gray-500">{emailUsuario}</div>
            </div>
        </div>
        {menuToggle &&
            <>
                <div className="mt-3 space-y-1">
                    <ButtonSwitchTheme />
                </div>
                <div className="mt-3 space-y-1">
                    <NavLink
                        to={URL_LOGOUT}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                        Salir
                    </NavLink>
                </div>
            </>
        }
    </>);
}

function TopMenuUser() {
    return (<>
        <div className="hidden sm:flex">
            <TopMenuUserDesktop />
        </div>
        <div className="sm:hidden">
            <TopMenuUserMobile />
        </div>
    </>);
}


export default TopMenuUser;