import { TrendingUp, TrendingDown, ArrowRight } from 'react-feather';
import { classNames } from "../../utils"
import { BoxContainer } from '../box';
import { Spinner } from "../spinner";

function Card({ title, value, trending, color, ...props }) {

    const up = trending !== null && trending !== undefined ? parseInt(trending) > 0 : false;

    return (
        <BoxContainer className="pt-5 px-4 sm:pt-6 sm:px-6">
            <dt>
                <div className={classNames(color, 'absolute rounded-md p-3')}>
                    <props.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 dark:text-gray-400 truncate">{title}</p>
            </dt>
            <dd className="ml-16 py-3 flex justify-between items-baseline md:block lg:flex sm:pb-7">
                <div className="flex items-baseline text-sm font-semibold text-gray-900 dark:text-gray-200">
                    {value && `${value}`}
                    {!value && <Spinner />}
                </div>
                {trending != null &&
                    <div
                        className={classNames(
                            up ? 'text-green-600' : trending === 0 ? 'text-gray-300' : 'text-red-600',
                            'ml-2 inline-flex items-baseline text-sm font-semibold'
                        )}
                    >
                        {up ? (
                            <TrendingUp className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                        ) : trending === 0 ? (
                            <ArrowRight className="self-center flex-shrink-0 h-5 w-5 text-gray-300" aria-hidden="true" />
                        ) : <TrendingDown className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />}

                        <span className="sr-only">{up ? 'Increased' : 'Decreased'} by</span>
                        {trending}
                    </div>
                }
            </dd>
        </BoxContainer>
    );

}

export { Card };