import { NombreEmpresa } from "./datosLocalStorage";

const ThemeLightName = "light";
const ThemeDarkName = "dark";
const SyncfusionThemeLight = "Tailwind"; // Tailwind,Fabric
const SyncfusionThemeDark = "TailwindDark"; // TailwindDark,FabricDark
const SyncfusionThemeLightCss = "/theme/syncfusion/tailwind.css";
const SyncfusionThemeDarkCss = "/theme/syncfusion/tailwind-dark.css";

export {
    NombreEmpresa as LocalStorageNombreEmpresa,
    ThemeLightName,
    ThemeDarkName,
    SyncfusionThemeLight,
    SyncfusionThemeDark,
    SyncfusionThemeLightCss,
    SyncfusionThemeDarkCss,
}