import { useState, useEffect } from "react";
import usePuntoPagosApi from "../services/usePuntoPagosApi";
import { useInterval } from "../utils/";
import { DashboardCardsComponent, DashboardChart } from "../components/dashboard"
import { Activity } from 'react-feather';
import { LocalStorageNombreEmpresa } from "../data/global";

import { HeaderBlack, HeaderBlackBottomSection, LayoutContainer } from "../components/layout";

function Dashboard() {

    const [datosDashboardEstadoCuenta, setDatosDashboardEstadoCuenta] = useState(null);
    const [datosDashboardResumenDia, setDatosDashboardResumenDia] = useState(null);
    const [datosDashboardResumenSemana, setDatosDashboardResumenSemana] = useState(null);
    const [datosDashboardResumenConversion, setDatosDashboardResumenConversion] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [nombreEmpresa] = useState(LocalStorageNombreEmpresa);
    
    const [ responseEstadoCuenta , setRequestEstadoCuenta] = usePuntoPagosApi(null);
    const [ responseResumenDia , setRequestResumenDia] = usePuntoPagosApi(null);
    const [ responseResumenSemana , setRequestResumenSemana] = usePuntoPagosApi(null);
    const [ responseResumenConversion , setRequestResumenConversion] = usePuntoPagosApi(null);
    
    useInterval(() => {
        setRefresh(refresh + 1);
    }, 60000 * 5);

    useEffect(() => {
        setRequestEstadoCuenta({ url: "cliente/dashboarddata/estadocuenta", data: null });
        setRequestResumenDia({ url: "cliente/dashboarddata/resumendia", data: null });
        setRequestResumenSemana({ url: "cliente/dashboarddata/resumensemana", data: null });
        setRequestResumenConversion({ url: "cliente/dashboarddata/resumenconversion", data: null });
    }, [refresh,setRequestEstadoCuenta,setRequestResumenDia,setRequestResumenSemana,setRequestResumenConversion]);

    useEffect(() => {
        if (responseEstadoCuenta != null && responseEstadoCuenta.response != null) {
            setDatosDashboardEstadoCuenta(responseEstadoCuenta.response.data);
        }
    }, [responseEstadoCuenta]);

    useEffect(() => {
        if (responseResumenDia != null && responseResumenDia.response != null) {
            setDatosDashboardResumenDia(responseResumenDia.response.data);
        }
    }, [responseResumenDia]);

    useEffect(() => {
        if (responseResumenSemana != null && responseResumenSemana.response != null) {
            setDatosDashboardResumenSemana(responseResumenSemana.response.data);
        }
    }, [responseResumenSemana]);

    useEffect(() => {
        if (responseResumenConversion != null && responseResumenConversion.response != null) {
            setDatosDashboardResumenConversion(responseResumenConversion.response.data);
        }
    }, [responseResumenConversion]);

    return (<>
        <HeaderBlack title="Dashboard" subtitle={nombreEmpresa} icon={Activity} />
        <HeaderBlackBottomSection>
            <DashboardCardsComponent
                datosDashboardEstadoCuenta={datosDashboardEstadoCuenta}
                datosDashboardResumenDia={datosDashboardResumenDia}
                datosDashboardResumenSemana={datosDashboardResumenSemana}
            />
        </HeaderBlackBottomSection>
        {datosDashboardResumenConversion &&
            <LayoutContainer>
                <DashboardChart datosDashboard={datosDashboardResumenConversion} />
            </LayoutContainer>
        }
    </>)
}

export default Dashboard;