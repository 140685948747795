import React, { Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { L10n, setCulture, setCurrencyCode, loadCldr } from '@syncfusion/ej2-base';
import * as gregorian from 'cldr-data/main/es-CL/ca-gregorian.json';
import * as numbers from 'cldr-data/main/es-CL/numbers.json';
import * as currencies from 'cldr-data/main/es-CL/currencies.json';
import * as dates from 'cldr-data/main/es-CL/dateFields.json';
import * as timeZoneNames from 'cldr-data/main/es-CL/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import { LayoutBase, LayoutSecure } from "./components/layout";
import {
  Login,
  Error404,
  Home,
  Loading
} from "./pages"
import apiConfig from "./data/config";
import useThemeService from "./services/themeService";
import { Helmet } from 'react-helmet';

loadCldr(numberingSystems, gregorian, numbers, currencies, dates, timeZoneNames);
setCulture('es-CL');
setCurrencyCode('CLP');
L10n.load(apiConfig.traduccionSyncfusion);

const Dashboard = React.lazy(() => import('./pages/dashboard'));
const Retiros = React.lazy(() => import('./pages/retiros'));
const SolicitaRetiro = React.lazy(() => import('./pages/retiros-solicitar'));
const Transacciones = React.lazy(() => import('./pages/transacciones'));
const TransaccionesInformes = React.lazy(() => import('./pages/transacciones-informes'));
const Usuarios = React.lazy(() => import('./pages/usuarios'));
const UsuariosNuevo = React.lazy(() => import('./pages/usuarios-nuevo'));
const PuntoPagosTransacciones = React.lazy(() => import('./pages/puntopagos/transacciones'));
const PuntoPagosInformes = React.lazy(() => import('./pages/puntopagos/informes'));
const PuntoPagosClientes = React.lazy(() => import('./pages/puntopagos/clientes'));
const PuntoPagosRetiros = React.lazy(() => import('./pages/puntopagos/retiros'));
const PuntoPagosBancos = React.lazy(() => import('./pages/puntopagos/bancos'));

const routes = [
  { path: "/", element: <Home />, exact: true },
  { path: "/dashboard", element: <Dashboard />, exact: true },
  { path: "/retiros", element: <Retiros /> },
  { path: "/retiros-solicitar", element: <SolicitaRetiro /> },
  { path: "/transacciones", element: <Transacciones /> },
  { path: "/transacciones-informes", element: <TransaccionesInformes /> },
  { path: "/usuarios", element: <Usuarios /> },
  { path: "/usuarios-nuevo", element: <UsuariosNuevo /> },
  { path: "/pp/transacciones", element: <PuntoPagosTransacciones /> },
  { path: "/pp/informes", element: <PuntoPagosInformes /> },
  { path: "/pp/clientes", element: <PuntoPagosClientes /> },
  { path: "/pp/retiros", element: <PuntoPagosRetiros /> },
  { path: "/pp/bancos", element: <PuntoPagosBancos /> },
];

export default function App() {

  const [{ }, switchTheme, getSyncfusionCssForTheme] = useThemeService();
  const [syncfusionThemeCss, setSyncfusionThemeCss] = React.useState(getSyncfusionCssForTheme());

  React.useEffect(() => {

    const interval = setInterval(() => {

      const css = getSyncfusionCssForTheme();

      if (css !== syncfusionThemeCss) {
        setSyncfusionThemeCss(css);
      }

    }, 1000);

    return () => clearInterval(interval);

  }, [syncfusionThemeCss, getSyncfusionCssForTheme]);

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={syncfusionThemeCss} />
      </Helmet>
      <Routes>
        <Route element={
          <LayoutSecure>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </LayoutSecure>
        }>
          {routes.map((route, i) => (
            <Route key={i} {...route} />
          ))}
        </Route>
        <Route element={<LayoutBase />}>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Login />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </>
  );
}