import Cookies from 'js-cookie';
import apiConfig from '../data/config';

const getRole = () => {
    let cookie = Cookies.get(apiConfig.authCookie);
    if (cookie === undefined)
        return { role: null, rolePP: null };
    let base64Url = cookie.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let payload = JSON.parse(jsonPayload);

    return {
        role: payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
        rolePP: payload['rpp'],
        clientID: payload['client_id'],
        username: payload['unique_name']
    };
};

const auth = () =>  {
    return getRole();
};

export default auth;
