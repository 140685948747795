import Logo from "../components/logo";

function Loading() {
    return (<>
        <div className="py-10">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <Logo className="mx-auto h-20 w-auto" />
                </div>
            </div>
        </div>

    </>)
}

export default Loading;