import { ColumnsDirective, GridComponent, ColumnDirective, Page, Inject, Filter } from "@syncfusion/ej2-react-grids";
import { BoxContainer } from "../box";
import { ErrorBoundary } from "../error";
import { TextMonoTokenTemplate } from "../transacciones";
import { WithdrawalButton } from "./withdrawal-button";
import { Helmet } from 'react-helmet';

function WithdrawalTablePP({ ...props }) {

    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`.e-filterbar{ display: none; }`}
                </style>
            </Helmet>
            <ErrorBoundary>
                <BoxContainer>
                    <GridComponent
                        allowPaging={true}
                        allowFiltering={true}
                        height={"auto"}
                        delayUpdate={true}
                        pageSettings={props.pageSettings}
                        filterSettings={props.filterSettings}
                        actionBegin={props.actionBegin}
                        ref={g => props.setGridRef(g)}
                        beforeDataBound={props.beforeDataBound}
                    >
                        <ColumnsDirective>
                            <ColumnDirective
                                field="clienteID"
                                visible={false}
                            />
                            <ColumnDirective
                                field="estadoID"
                                visible={false}
                            />
                            <ColumnDirective
                                field="fechaInicial"
                                visible={false}
                            />
                            <ColumnDirective
                                field="fechaFinal"
                                visible={false}
                            />
                            <ColumnDirective
                                field='retiroID'
                                headerText='ID'
                                clipMode="EllipsisWithTooltip"
                                template={(data) => TextMonoTokenTemplate(data.retiroID)}
                                width={150}
                                isPrimaryKey={true}
                            />
                            <ColumnDirective
                                field='fecha'
                                headerText='Fecha'
                                clipMode="EllipsisWithTooltip"
                                textAlign='Left'
                                width={120}
                                type="dateTime"
                                format='dd-MM-yyyy HH:mm'
                            />
                            <ColumnDirective
                                field='banco'
                                headerText='Banco'
                                clipMode="EllipsisWithTooltip"
                                width={120}
                            />
                            <ColumnDirective
                                field='numeroCuenta'
                                headerText='Cuenta'
                                clipMode="EllipsisWithTooltip"
                                width={120}
                            />
                            <ColumnDirective
                                field='monto'
                                headerText='Monto'
                                clipMode="EllipsisWithTooltip"
                                type='number'
                                format='C2'
                                textAlign='Right'
                                width={100}
                            />
                            <ColumnDirective
                                field='nombre'
                                headerText='Cliente'
                                clipMode="EllipsisWithTooltip"
                                width={150}
                            />
                            <ColumnDirective
                                field='razonSocial'
                                headerText='Razón Social'
                                clipMode="EllipsisWithTooltip"
                                width={120}
                            />
                            <ColumnDirective
                                field='rut'
                                headerText='RUT'
                                clipMode="EllipsisWithTooltip"
                                width={100}
                            />
                            <ColumnDirective
                                field='usuario'
                                headerText='Solicitado Por'
                                clipMode="EllipsisWithTooltip"
                                width={120}
                            />
                            <ColumnDirective
                                field='estado'
                                headerText='Estado'
                                clipMode="EllipsisWithTooltip"
                                width={120}
                            />
                            <ColumnDirective
                                field='fechaPago'
                                headerText='Fecha Pago'
                                clipMode="EllipsisWithTooltip"
                                textAlign='Left'
                                width={120}
                                type="dateTime"
                                format='dd-MM-yyyy HH:mm'
                            />
                            <ColumnDirective
                                field="verRetiro"
                                headerText=""
                                width={30}
                                template={(data) => WithdrawalButton({ ...data })}
                            />
                        </ColumnsDirective>
                        <Inject services={[Page, Filter]} />
                    </GridComponent>
                </BoxContainer>
            </ErrorBoundary>
        </>)
}

export { WithdrawalTablePP }