import React from "react";
import { LayoutContainer } from "../components/layout";
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import apiConfig from "../data/config";
import { WithdrawalTable } from "../components/retiros";

function Retiros() {

    const [dataSource] = React.useState(new DataManager({
        adaptor: new UrlAdaptor(),
        url: `${apiConfig.baseUrl}cliente/withdrawals`
    }));
    const [gridRef, setGridRef] = React.useState();
    const defaultPageSettings = { currentPage: 1, pageSize: 20 };

    React.useEffect(() => {

        if (gridRef && dataSource !== null) {
            gridRef.dataSource = dataSource;
        }

    }, [gridRef, dataSource]);

    return (<>

        <LayoutContainer
            title="Listado de Retiros"
        >
            <div className="flex-grow w-full lg:flex">
                <div className="h-full mt-6 xl:mt-0">
                    <WithdrawalTable
                        setGridRef={setGridRef}
                        pageSettings={defaultPageSettings}
                    />
                </div>
            </div>
        </LayoutContainer>
    </>)
}

export default Retiros;