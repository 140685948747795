import React from "react";
import { useNavigate } from 'react-router-dom';
import auth from "../services/auth";

function Home() {

    let navigate = useNavigate();

    React.useEffect(() => {
        const role = auth().role;
        let path = role !== null
                ? ["Administrador", "Operador", "Contralor"].includes(role) ? "/dashboard" : "/transacciones"
                : "/login";
        
        navigate(path, {});

    }, [navigate]);

    return (<></>)
}

export default Home;