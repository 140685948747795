import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import apiConfig from "../data/config";

const usePuntoPagosApi = (initialResponse) => {
  let navigate = useNavigate();

  const [response, setResponse] = React.useState(initialResponse);
  const [request, setRequest] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {

    const fetchData = async () => {

      if (request === null) {
        setResponse(initialResponse);
        return;
      }

      setError(null);
      setIsLoading(true);

      try {

        const method = (request.method ?? "POST").toLowerCase();
        const url = `${apiConfig.baseUrl}${request.url}`;

        const result = await (request.data == null
          ? axios[method](url)
          : axios[method](url, request.data));
        setResponse(result);

      } catch (error) {
        if (error.response.status === 401)
          navigate("/login", {});
        else if (error.response.status === 403)
          setError("El nombre de usuario o el password es incorrecto.");
        else
          setError("Ha ocurrido un error al procesar su solicitud");
      }
      setIsLoading(false);
    };

    fetchData();
  }, [request, navigate, initialResponse]);

  return [{ response, isLoading, error }, setRequest];
}

export default usePuntoPagosApi;
