import React from "react";
import { BoxContainer } from "../box";
import { ErrorBoundary } from "../error";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject } from '@syncfusion/ej2-react-grids';
import { TextMonoTokenTemplate } from "../transacciones";
import { WithdrawalButton } from "./withdrawal-button";
import auth from "../../services/auth";

function WithdrawalTable({ ...props }) {

    const authRole = auth();
    
    return (
        <ErrorBoundary>
            <BoxContainer>
                <GridComponent
                    allowPaging={true}
                    allowFiltering={false}
                    height={"auto"}
                    delayUpdate={true}
                    pageSettings={props.pageSettings}
                    ref={g => props.setGridRef(g)}
                >
                    <ColumnsDirective>
                        <ColumnDirective
                            field='retiroID'
                            headerText='ID'
                            clipMode="EllipsisWithTooltip"
                            template={(data) => TextMonoTokenTemplate(data.retiroID)}
                            width={150}
                            isPrimaryKey={true}
                        />
                        <ColumnDirective
                            field='fecha'
                            headerText='Fecha'
                            clipMode="EllipsisWithTooltip"
                            textAlign='Left'
                            width={150}
                            type="dateTime"
                            format='dd-MM-yyyy HH:mm'
                        />
                        <ColumnDirective
                            field='banco'
                            headerText='Banco'
                            clipMode="EllipsisWithTooltip"
                            width={120}
                        />
                        <ColumnDirective
                            field='numeroCuenta'
                            headerText='Cuenta'
                            clipMode="EllipsisWithTooltip"
                            width={120}
                        />
                        <ColumnDirective
                            field='monto'
                            headerText='Monto'
                            clipMode="EllipsisWithTooltip"
                            type='number'
                            format='C2'
                            textAlign='Right'
                            width={150}
                        />
                        <ColumnDirective
                            field='usuario'
                            headerText='Solicitado Por'
                            clipMode="EllipsisWithTooltip"
                            width={120}
                        />
                        <ColumnDirective
                            field='estado'
                            headerText='Estado'
                            clipMode="EllipsisWithTooltip"
                            width={120}
                        />
                        <ColumnDirective
                            field='fechaPago'
                            headerText='Fecha Pago'
                            clipMode="EllipsisWithTooltip"
                            textAlign='Left'
                            width={150}
                            type="dateTime"
                            format='dd-MM-yyyy HH:mm'
                        />
                        {authRole.clientID === '2' &&
                            <ColumnDirective
                                field="verRetiro"
                                headerText=""
                                width={30}
                                template={(data) => WithdrawalButton({ ...data })}
                            />
                        }
                    </ColumnsDirective>
                    <Inject services={[Page]} />
                </GridComponent>
            </BoxContainer>
        </ErrorBoundary>
    );
}

export { WithdrawalTable }