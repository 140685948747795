
import { TextBoxComponent, NumericTextBoxComponent  } from '@syncfusion/ej2-react-inputs';

function Input({ ...props }) {
    return (
        <TextBoxComponent 
            {...props}
            showClearButton= {true}
        />
    );
}

function InputMono({ ...props }) {
    return (
        <TextBoxComponent 
            {...props}
            showClearButton={true}
            className="e-input font-mono slashed-zero tabular-nums tracking-widest"
        />
    );
}

function InputNumeric({ ...props }) {
    return (
        <NumericTextBoxComponent  
            {...props}
            showClearButton= {true}
        />
    );
}

export { Input, InputMono, InputNumeric };