function GetDatoLocalStorage(storage, dato) {
    let item = localStorage.getItem(storage);

    if (item === null) {
        return null;
    }
    
    const datos = JSON.parse(item)
    return datos[dato];
}

function NombreEmpresa() {
    return GetDatoLocalStorage("datosEmpresa", "empresa");
}

function NombreUsuario() {
    return GetDatoLocalStorage("datosEmpresa", "usuario");
}

function EmailUsuario() {
    return GetDatoLocalStorage("datosEmpresa", "email");
}

export { GetDatoLocalStorage, NombreEmpresa, NombreUsuario, EmailUsuario };