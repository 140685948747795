import React from 'react';
import { classNames } from "../../utils"
import { Sun, Moon, Loader } from 'react-feather';
import useThemeService from "../../services/themeService";

function Button({ ...props }) {

    const [isDisabled, setIsDisabled] = React.useState(false);

    const onClick = async (event) => {

        event.preventDefault();

        if (props.onClick) {
            setIsDisabled(true);

            await props.onClick(event);

            setIsDisabled(false);
        }
    };

    return (
        <button
            type="button"
            className={classNames(isDisabled ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-600 dark:hover:bg-gray-600", "ml-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-500 text-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all text-sm dark:bg-gray-700 dark:focus:ring-offset-gray-800")}
            onClick={onClick}
            disabled={isDisabled}
        >
            {isDisabled ? <>{props.loadingText ?? "...cargando"}</> : <>{props.children}</>}
        </button>
    );
}

function ButtonIcon({ ...props }) {
    const [isDisabled, setIsDisabled] = React.useState(false);

    const onClick = async (event) => {

        event.preventDefault();

        setIsDisabled(true);

        await props.onClick(event);

        setIsDisabled(false);
    };

    return (
        <button
            type="button"
            className={classNames(isDisabled ? "opacity-50 cursor-not-allowed" : "", "p-3 inline-flex justify-center items-center gap-2 rounded-md font-semibold transition-all text-sm")}
            onClick={onClick}
            disabled={isDisabled}
            title={props.title}
        >
            {!isDisabled && <>
                {props.children}
            </>}
            {isDisabled && <>
                <Loader className="h-3 w-3 text-gray-400" aria-hidden="true" />
            </>}
        </button>
    );
}

function ButtonSwitchTheme() {

    const [{ theme, ThemeDarkName }, switchTheme] = useThemeService();

    return (
        <button
            type="button"
            onClick={switchTheme}
            className={'hover:bg-gray-100 block w-full text-left px-4 py-2 text-sm text-gray-700'}
        >
            {theme === ThemeDarkName &&
                <Sun className="h-5 w-5 text-gray-700" aria-hidden="true" />
            }

            {theme !== ThemeDarkName &&
                <Moon className="h-5 w-5 text-gray-700" aria-hidden="true" />
            }
        </button>
    );
}

export { Button, ButtonSwitchTheme, ButtonIcon };