import React from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { BoxContainer } from "../box"
import { Button } from "../button"
import { ErrorBoundary } from "../error"
import { Label } from "../label";
import { InputMono, InputNumeric } from "../input";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";

function WithdrawalFinder({
    filter,
    clearFilter,
    isLoading,
    setIsLoading,
    dataSourceClientes,
    dataSourceEstadoRetiro
}) {

    const limitMaxDate = new Date(Date.now());
    const fieldsDropDownListClientes = { text: 'descripcion', value: 'clienteID' };
    const fieldsDropDownList = { text: 'estado', value: 'estadoID' };

    const [monthStart] = React.useState(new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString()));
    const [monthEnd] = React.useState(new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()));

    const [inputRetiroID, setInputRetiroID] = React.useState("");
    const [inputMonto, setInputMonto] = React.useState("");

    const [dateRangeRef, setDateRangeRef] = React.useState();
    const [clienteRef, setClienteRef] = React.useState();
    const [estadoRef, setEstadoRef] = React.useState();

    React.useEffect(() => { 
        
        if (dateRangeRef) {
            dateRangeRef.value = [monthStart, monthEnd];
        }

    }, [dateRangeRef, monthStart, monthEnd]);
    
    const clearFilterForm = async (event) => {

        event.preventDefault();

        await clearFilter();

        setInputRetiroID('');
        setInputMonto('');

        estadoRef.clear();
        clienteRef.clear();

        dateRangeRef.value = [monthStart, monthEnd];

        setIsLoading(false);
    };

    const buscar = async (event) => {
        event.preventDefault();

        const query = [];

        const dateRange = dateRangeRef.getSelectedRange();

        const startDate = dateRange.startDate === null ? undefined : dateRange.startDate;
        const endDate = dateRange.endDate === null ? undefined : dateRange.endDate;
        const clienteID = clienteRef.value;
        const estado = estadoRef.value;

        let inputs = [
            { field: 'clienteID', operator: 'equal', value: clienteID },
            { field: 'estadoID', operator: 'equal', value: estado },
            { field: 'monto', operator: 'equal', value: inputMonto },
            { field: 'fechaInicial', operator: 'greaterthan', value: startDate },
            { field: 'fechaFinal', operator: 'lessthan', value: endDate }
        ];

        if (inputRetiroID !== "") {
            inputs = [{ field: 'retiroID', operator: 'equal', value: inputRetiroID }];
        }

        inputs.forEach((e) => {
            if (e.value && e.value !== '') {
                query.push(e);
            }
        });

        await filter(query);
    };

    return (<ErrorBoundary>
        <BoxContainer className="px-4 sm:px-6 pb-6">
            <form className="space-y-8 divide-y divide-gray-200 dark:divide-gray-600">
                <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-600">
                    <div>
                        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-1 gap-y-6 gap-x-4">
                            <div>
                                <Label
                                    htmlFor="ClienteID"
                                >
                                    Cliente
                                </Label>
                                <DropDownListComponent
                                    ref={r => setClienteRef(r)}
                                    id="ClienteID"
                                    name="ClienteID"
                                    fields={fieldsDropDownListClientes}
                                    dataSource={dataSourceClientes}
                                    showClearButton={true}
                                />
                            </div>
                            <div>
                                <Label
                                    htmlFor="Estado"
                                >
                                    Estado
                                </Label>
                                <DropDownListComponent
                                    ref={r => setEstadoRef(r)}
                                    id="Estado"
                                    name="Estado"
                                    fields={fieldsDropDownList}
                                    dataSource={dataSourceEstadoRetiro}
                                    showClearButton={true}
                                    value={1}
                                />
                            </div>
                            <div>
                                <Label
                                    htmlFor="RetiroID"
                                >
                                    ID
                                </Label>
                                <InputMono
                                    type="text"
                                    name="RetiroID"
                                    id="RetiroID"
                                    value={inputRetiroID}
                                    className="font-mono slashed-zero tabular-nums tracking-widest"
                                    onChange={(x) => setInputRetiroID(x.target.value)}
                                />
                            </div>
                            <div>
                                <Label
                                    htmlFor="Monto"
                                >
                                    Monto
                                </Label>
                                <InputNumeric
                                    name="Monto"
                                    id="Monto"
                                    min={0}
                                    format='c2'
                                    validateDecimalOnType={true}
                                    decimals={2}
                                    showSpinButton={false}
                                    onChange={(x) => setInputMonto(x.target.value)}
                                    value={inputMonto}
                                />
                            </div>
                            <div>
                                <Label
                                    htmlFor="Fecha"
                                >
                                    Fecha
                                </Label>
                                <DateRangePickerComponent
                                    strictMode={true}
                                    ref={(r) => setDateRangeRef(r)}
                                    firstDayOfWeek={1}
                                    max={limitMaxDate}
                                    maxDays={31}
                                    openOnFocus={true}
                                    serverTimezoneOffset={"-0400"}
                                    name="Fecha"
                                    id="Fecha"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="pt-5">
                            <div className="flex justify-end">
                                {!isLoading && <>
                                    <Button
                                        onClick={clearFilterForm}
                                        loadingText={"Limpiando.."}>
                                        Limpiar
                                    </Button>
                                    <Button
                                        onClick={buscar}
                                        loadingText={"Buscando.."}>
                                        Buscar
                                    </Button>
                                </>}
                                {isLoading && <>
                                    <Button
                                            onClick={clearFilterForm}>
                                        ...cargando
                                    </Button>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BoxContainer>
    </ErrorBoundary>)
}

export { WithdrawalFinder }