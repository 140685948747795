export const apiConfig = {
    baseUrl: process.env.REACT_APP_API_URL,
    authCookie: '_thp',
    traduccionSyncfusion: {
        'es-CL': {
            'grid': {
                'EmptyDataSourceError': 'Datasource Error',
                'EmptyRecord': 'No hay registros que mostrar',
                'Item': 'Registro',
                'Items': 'Registros',
                'Excelexport': 'Exportar a Excel'
            },
            'pager':{
                'currentPageInfo': '{0} de {1} páginas',
                'firstPageTooltip': 'Ir a la primera página',
                'lastPageTooltip': 'Ir a la última página',
                'nextPageTooltip': 'Ir a la próxima página',
                'previousPageTooltip': 'Ir a la página anterior',
                'totalItemsInfo': '({0} Registros)'
            },
            'daterangepicker':{
                'placeholder': 'Rango Fechas',
                'startLabel': 'Fecha Inicial',
                'endLabel': 'Fecha Final',
                'applyText': 'Seleccionar',
                'cancelText': 'Cancelar',
                'selectedDays': 'Rango Seleccionado',
                'days': 'Días',
                'customRange': 'Custom Range'
            }
        }
      }
};

export default apiConfig;