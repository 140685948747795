import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AlertErrorDescription } from "../components/alert";
import { classNames } from "../utils";
import usePuntoPagosApi from "../services/usePuntoPagosApi";
import apiConfig from "../data/config";
import { Label } from "../components/label";

function Login() {

    let navigate = useNavigate();

    const [nombreUsuario, setNombreUsuario] = useState();
    const [password, setPassword] = useState();

    const [{ response, isLoading, error }, setRequest] = usePuntoPagosApi();

    const validateForm = function () {
        return nombreUsuario && password && nombreUsuario.length > 3 && password.length > 3;
    };

    const login = function (event) {
        event.preventDefault();

        setRequest({ url: "cuenta/login", data: { nombreUsuario: nombreUsuario, password: password } });

        return true;
    };

    useEffect(() => {
        Cookies.remove(apiConfig.authCookie);
    }, []);

    useEffect(() => {
        if (response && response.status === 200) {
            localStorage.setItem("datosEmpresa", JSON.stringify(response.data));
            navigate("/", {});
        } else {
            localStorage.removeItem("datosEmpresa");
        }

    }, [response, navigate]);

    return (<>

        <div className="bg-white dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
                <div>
                    <Label htmlFor="usuario">
                        Usuario
                    </Label>
                    <div className="mt-1">
                        <input
                            id="usuario"
                            name="usuario"
                            type="usuario"
                            autoComplete="usuario"
                            placeholder="Ingresa el Nombre de Usuario"
                            required
                            onChange={(ev) => setNombreUsuario(ev.target.value)}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>

                <div>
                    <Label htmlFor="password">
                        Contraseña
                    </Label>
                    <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="password"
                            placeholder="Ingresa la Contraseña"
                            required
                            onChange={(ev) => setPassword(ev.target.value)}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        className={classNames(!validateForm() ? "" : "hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500", "disabled:opacity-75 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600")}
                        disabled={!validateForm() || isLoading}
                        onClick={(e) => login(e)}
                    >
                        Login
                    </button>
                </div>
            </form>
            {error &&
                <div className="mt-5">
                    <AlertErrorDescription text={error} />
                </div>
            }
        </div>
    </>)
}

export default Login;