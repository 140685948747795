import { XCircle } from 'react-feather';

function AlertErrorDescription({ text }) {
    return (<>
        <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircle  className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{text}</h3>
                </div>
            </div>
        </div>
    </>)
}

export { AlertErrorDescription }