import { Disclosure } from '@headlessui/react'
import { Menu as MenuIcon, X } from 'react-feather';

import { TopMenuItem, TopMenuUser } from './'

import Logo from '../logo';

function TopMenu({ menu, userRole }) {

    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="flex-shrink-0 flex items-center">
                                    <Logo className="block h-12 w-auto" />
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {menu.map((menuItem, i) =>
                                        <TopMenuItem key={i} mobile={false} userRole={userRole} {...menuItem} />
                                    )}
                                </div>
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                <TopMenuUser />
                            </div>
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Menú</span>
                                    {open ? (
                                        <X className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {menu.map((menuItem, i) =>
                                <TopMenuItem key={i} mobile={true} userRole={userRole} {...menuItem} />
                            )}
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-200">
                            <TopMenuUser />
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default TopMenu;