import React from "react";
import { LayoutContainer } from "../components/layout";
import { Button } from "../components/button";
import usePuntoPagosApi from "../services/usePuntoPagosApi";
import { formatNumber } from "../utils";
import { Input, InputNumeric } from "../components/input";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Label } from "../components/label";

function SolicitaRetiro() {

    const fieldsDropDownList = { text: 'descripcion', value: 'cuentaID' };

    const [saldo, setSaldo] = React.useState(null);
    const [cuentas, setCuentas] = React.useState(null);
    const [inputMonto, setInputMonto] = React.useState("");
    const [cuentaRef, setCuentaRef] = React.useState();

    const [retiroID, setRetiroID] = React.useState(null);

    const [responseSaldoCuenta, setRequestSaldoCuenta] = usePuntoPagosApi(null);
    const [resultRetiro, setResultRetiro] = usePuntoPagosApi(null);

    React.useEffect(() => {
        setRequestSaldoCuenta({ url: "cliente/saldoretiros", data: null });
    }, [setRequestSaldoCuenta]);

    React.useEffect(() => {
        if (responseSaldoCuenta != null && responseSaldoCuenta.response != null && !responseSaldoCuenta.isLoading) {
            setSaldo(formatNumber(responseSaldoCuenta.response.data.saldo, 2));
            setCuentas(responseSaldoCuenta.response.data.cuentas);
        }
    }, [responseSaldoCuenta, responseSaldoCuenta.response, responseSaldoCuenta.error, responseSaldoCuenta.isLoading]);

    React.useEffect(() => {

        if (resultRetiro != null && resultRetiro.response != null && !resultRetiro.isLoading) {
            if (resultRetiro.response.status === 200) {
                if (retiroID === null) {
                    setRetiroID(resultRetiro.response.data);
                }
            } else {
                alert("Error: " + resultRetiro.error);
            }
        }

    }, [resultRetiro, resultRetiro.response, resultRetiro.error, resultRetiro.isLoading, retiroID]);

    const handleButtonClick = (event) => {
        event.preventDefault();

        const data = {
            CuentaID: cuentaRef.value,
            Monto: inputMonto
        };

        setResultRetiro({ url: "cliente/SolicitarRetiro", data: data });
    };

    return (<>
        <LayoutContainer>
            <div className="space-y-10 divide-y divide-gray-900/10 dark:divide-gray-600/10">
                <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">Solicitar Retiro</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300">
                            Ingrese el monto del retiro
                        </p>
                    </div>
                    <form className="bg-white dark:bg-gray-800 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                                <div className="sm:col-span-1">
                                    <Label
                                        htmlFor="Saldo">
                                        Saldo
                                    </Label>
                                    <div className="mt-2">
                                        <Input
                                            name="Saldo"
                                            readOnly={true}
                                            type="text"
                                            value={saldo}
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-1">
                                    <Label
                                        htmlFor="Monto">
                                        Monto del Retiro
                                    </Label>
                                    <div className="mt-2">
                                        <InputNumeric
                                            name="Monto"
                                            id="Monto"
                                            min={0}
                                            format='c2'
                                            validateDecimalOnType={true}
                                            decimals={2}
                                            showSpinButton={false}
                                            readOnly={retiroID != null}
                                            disabled={retiroID != null}
                                            onChange={(x) => setInputMonto(x.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-2">
                                    <Label
                                        htmlFor="Cuenta">
                                        Cuenta Destino
                                    </Label>
                                    <div className="mt-2">
                                        <DropDownListComponent
                                            ref={r => setCuentaRef(r)}
                                            id="Cuenta"
                                            name="Cuenta"
                                            fields={fieldsDropDownList}
                                            dataSource={cuentas}
                                            showClearButton={retiroID === null}
                                            readOnly={retiroID != null}
                                            disabled={retiroID != null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            {inputMonto && cuentaRef && !resultRetiro.isLoading && retiroID === null &&
                                <Button
                                    onClick={handleButtonClick}
                                    loadingText={"Procesando..."}>
                                    Solicitar Retiro
                                </Button>
                            }
                            {retiroID != null &&
                                <>
                                    Retiro Solicitado: <span className="font-mono slashed-zero tabular-nums tracking-widest">{retiroID}</span>
                                </>
                            }
                        </div>
                    </form>

                </div>
            </div>
        </LayoutContainer>
    </>)
}

export default SolicitaRetiro;