import React from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Filter } from '@syncfusion/ej2-react-grids';
import { ErrorBoundary } from "../error";
import { BoxContainer } from "../box";
import { TextMonoTokenTemplate, TransactionModalButton } from "./"
import { Helmet } from 'react-helmet';
import auth from "../../services/auth";

function TransactionTable({ ...props }) {

    const authRole = auth();

    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`.e-filterbar{ display: none; }`}
                </style>
            </Helmet>
            <ErrorBoundary>
                <BoxContainer>

                    <GridComponent
                        allowPaging={true}
                        allowFiltering={true}
                        height={"auto"}
                        delayUpdate={true}
                        pageSettings={props.pageSettings}
                        filterSettings={props.filterSettings}
                        actionBegin={props.actionBegin}
                        ref={g => props.setGridRef(g)}
                        beforeDataBound={props.beforeDataBound}
                    >
                        <ColumnsDirective>
                            <ColumnDirective
                                field='fecha'
                                headerText='Fecha'
                                clipMode="EllipsisWithTooltip"
                                textAlign='Left'
                                width={150}
                                type="dateTime"
                                format='dd-MM-yyyy HH:mm:ss'
                            />
                            <ColumnDirective
                                field='fechaInicial'
                                visible={false}
                            />
                            <ColumnDirective
                                field='fechaFinal'
                                visible={false}
                            />
                            <ColumnDirective
                                field='verTransaccion'
                                headerText=''
                                width={30}
                                template={(data) => TransactionModalButton({ ...data }, props.urlTransactionHistory)}
                            />
                            <ColumnDirective
                                field='transaccionID'
                                headerText='Token'
                                clipMode="EllipsisWithTooltip"
                                template={(data) => TextMonoTokenTemplate(data.transaccionID)}
                                width={150}
                                isPrimaryKey={true}
                            />
                            {authRole.clientID === '2' &&
                                <ColumnDirective
                                    field="estadoConciliacionID"
                                    headerText="Conciliada"
                                    width={100}
                                    template={(data) => <>{data.estadoConciliacionID === 0 ? '':'Conciliada'}</>}
                                />
                            }
                            <ColumnDirective
                                field="clienteID"
                                visible={false}
                            />
                            {props.showCliente &&
                                <ColumnDirective
                                    field='cliente'
                                    headerText='Cliente'
                                    clipMode="EllipsisWithTooltip"
                                    width={180}
                                />
                            }
                            <ColumnDirective
                                field='clienteTrxID'
                                headerText='TrxID'
                                clipMode="EllipsisWithTooltip"
                                width={120}
                            />
                            <ColumnDirective
                                field='correlativo'
                                headerText='Correlativo'
                                clipMode="EllipsisWithTooltip"
                                width={100}
                            />
                            <ColumnDirective
                                field='medioPago'
                                headerText='Medio de Pago'
                                clipMode="EllipsisWithTooltip"
                                width={180}
                            />
                            <ColumnDirective
                                field='estado'
                                headerText='Estado'
                                textAlign='left'
                                clipMode="EllipsisWithTooltip"
                                width={120}
                            />
                            <ColumnDirective
                                field='codigoAutorizacion'
                                headerText='Cód.Aut.'
                                clipMode="EllipsisWithTooltip"
                                width={100}
                            />
                            <ColumnDirective
                                field='finalTarjeta'
                                headerText='Tarjeta'
                                clipMode="EllipsisWithTooltip"
                                width={100}
                            />
                            <ColumnDirective
                                field='monto'
                                headerText='Monto'
                                clipMode="EllipsisWithTooltip"
                                type='number'
                                format='C2'
                                textAlign='Right'
                                width={150}
                            />
                        </ColumnsDirective>
                        <Inject services={[Page, Filter]} />
                    </GridComponent>
                </BoxContainer>
            </ErrorBoundary>
        </>
    )
}

export { TransactionTable }