import {
    ChartComponent,
    SeriesCollectionDirective,
    AxesDirective,
    AxisDirective,
    SeriesDirective,
    Inject,
    LineSeries,
    DateTime,
    Legend,
    Tooltip,
} from "@syncfusion/ej2-react-charts";
import { ThemeLightName, SyncfusionThemeDark, SyncfusionThemeLight } from "../../data/global";
import { BoxContainer } from "../box";
import { ErrorBoundary } from "../error";

function DashboardChart({ datosDashboard }) {

    const chartpalette = ["#0061f2", "#6900c7", "#00ac69", "#f4a100"];

    return (<>
        {datosDashboard != null && datosDashboard !== undefined &&
            <ErrorBoundary>
                <BoxContainer className="py-3 px-5">
                    <ChartComponent
                    id="charts"
                        style={{ textAlign: "center" }}
                        useGroupingSeparator="true"
                        primaryXAxis={{
                            valueType: "DateTime",
                            labelFormat: "d/MM",
                            intervalType: "Days",
                            edgeLabelPlacement: "Shift",
                            labelIntersectAction: "Hide",
                            majorGridLines: { width: 0 },
                        }}
                        primaryYAxis={{
                            labelFormat: "C0",
                            rangePadding: "Auto",
                            minimum: 0,
                            lineStyle: { width: 0 },
                            majorTickLines: { width: 0 },
                            minorTickLines: { width: 0 },
                        }}
                        chartArea={{ border: { width: 0 } }}
                        tooltip={{ enable: true }}
                        palettes={chartpalette}
                        title="Ventas / Conversión Diaria"
                        theme={localStorage.theme === ThemeLightName ? SyncfusionThemeLight : SyncfusionThemeDark}
                        enableCanvas={false}
                    >
                        <Inject
                            services={[LineSeries, DateTime, Legend, Tooltip]}
                        />
                        <AxesDirective>
                            <AxisDirective
                                majorGridLines={{ width: 0 }}
                                rowIndex={0}
                                opposedPosition={true}
                                lineStyle={{ width: 0 }}
                                minimum={0}
                                majorTickLines={{ width: 0 }}
                                labelFormat="P0"
                                name="yAxis1"
                            ></AxisDirective>
                        </AxesDirective>
                        <SeriesCollectionDirective>
                            <SeriesDirective
                                dataSource={datosDashboard}
                                xName="dia"
                                yName="montoAprobado"
                                name="Venta"
                                width={2}
                                marker={{ visible: true, width: 10, height: 10 }}
                                type="Line"
                            ></SeriesDirective>
                            <SeriesDirective
                                dataSource={datosDashboard}
                                xName="dia"
                                yName="conversion"
                                name="Conversion"
                                width={2}
                                marker={{ visible: true, width: 5, height: 5 }}
                                type="Line"
                                yAxisName="yAxis1"
                            ></SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </BoxContainer>
            </ErrorBoundary>
        }
    </>);
}

export { DashboardChart };