//import { StrictMode } from 'react'; // TIENE ERRORES StrictMode 
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { registerLicense } from '@syncfusion/ej2-base';
import App from './app';
import "./global.css";

registerLicense(`${process.env.REACT_APP_SYNCFUSION_KEY}`);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Router>
    <App />
  </Router>
);