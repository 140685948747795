import React from 'react'
import { NavLink } from "react-router-dom";
import { Disclosure, Popover, Transition } from '@headlessui/react'
import { classNames } from '../../utils'
import { ChevronDown } from 'react-feather';

function TopMenuItem({ ...props }) {

    if (!props.allowed) {
        return (<></>);
    }

    if (props.items) {
        props.items = props.items.filter((i) => i.allowed === true);
    }

    return (props.mobile
        ? <TopMenuItemDisclosure {...props} />
        : <TopMenuItemA {...props} />
    )
}

function TopMenuItemA({ title, url, active, allowed, items }) {

    if (items === undefined || items.length === 0) {
        return (
            <>
                <NavLink
                    to={url}
                    className={classNames(active
                        ? 'border-indigo-500 text-gray-900'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium')}
                >
                    {title}
                </NavLink>
            </>
        )
    }
    else {
        return (
            <>
                <div
                    className={classNames(active
                        ? 'border-indigo-500 text-gray-900'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium')}
                >
                    <Popover className="relative">
                        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6">
                            <span>{title}</span>
                        </Popover.Button>

                        <Transition
                            as={React.Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute left-1/2 z-50 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                                <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                    {items.map((item) => (
                                        <NavLink
                                            key={item.url}
                                            to={item.url}
                                            className={item.active ? "block p-2 text-indigo-500 text-sm font-medium" : "block p-2 text-gray-500 hover:text-indigo-600 text-sm font-medium"}
                                        >
                                            {item.title}
                                        </NavLink>
                                    ))}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                </div>
            </>
        )
    }

}

function TopMenuItemDisclosure({ title, url, active, allowed, items }) {

    if (items === undefined || items.length === 0) {
        return (<>
            <Disclosure.Button as="div">
                <NavLink
                    to={url}
                    className={classNames(active
                        ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                        : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                        , 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium')}
                >
                    {title}
                </NavLink>
            </Disclosure.Button>
        </>);
    }
    else {
        return (
            <Disclosure
                className={classNames(active
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                    , 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium')}
            >
                {({ open }) =>
                    <div className="relative">
                        <Disclosure.Button className="flex w-full justify-between text-sm font-semibold leading-6">
                            <span>{title}</span>
                            <ChevronDown
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-4 w-4 text-gray-400`}
                                aria-hidden="true" />
                        </Disclosure.Button>
                        <Disclosure.Panel>
                            <div className="bg-gray-50 pt-4 pb-2 pl-4 mb-0 text-sm font-semibold text-gray-900">
                                {items.map((item) => (
                                    <Disclosure.Button
                                        key={item.url}
                                        href={item.url}
                                        as={"a"}
                                        className="block p-2 mb-2 text-gray-500 hover:text-indigo-600 text-sm font-medium leading-6">
                                        {item.title}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </div>
                }
            </Disclosure>
        );
    }
}

export default TopMenuItem;