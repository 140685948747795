import { DollarSign, ShoppingBag, TrendingUp, Percent } from 'react-feather';
import { Card } from "../card"
import { formatNumber } from "../../utils"

function DashboardCardsContainer({ ...props }) {
    return (<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6">{props.children}</dl>)
}

function DashboardCard({ ...props }) {

    if (props.number !== null && props.number !== undefined) {
        props.value = props.type === "P"
            ? formatNumber(props.number, 2, "", "%")
            : formatNumber(props.number);
    }
    return (
        <Card {...props} />
    );
}

function DashboardCards({ cards }) {
    return (
        <DashboardCardsContainer>
            {cards.map((card, i) => (<DashboardCard key={i} {...card} />))}
        </DashboardCardsContainer>
    );
}

function DashboardCardsComponent({ datosDashboardEstadoCuenta,datosDashboardResumenDia,datosDashboardResumenSemana }) {
    return (
        <DashboardCards cards={[
            {
                title: "Balance",
                number: datosDashboardEstadoCuenta?.balance,
                type: "C",
                color: "bg-blue-500",
                icon: DollarSign
            },
            {
                title: "Venta Día",
                number: datosDashboardResumenDia?.venta,
                type: "C",
                color: "bg-cyan-500",
                icon: ShoppingBag
            },
            {
                title: "Venta Ult. Semana",
                number: datosDashboardResumenSemana?.venta,
                type: "C",
                color: "bg-green-500",
                icon: TrendingUp,
                trending: datosDashboardResumenSemana?.tendenciaVenta
            },
            {
                title: "Conversión Ult. Semana",
                number: datosDashboardResumenSemana?.conversion,
                type: "P",
                color: "bg-yellow-500",
                icon: Percent,
                trending: datosDashboardResumenSemana?.tendenciaConversion
            }
        ]} />
    );
}

export { DashboardCardsComponent, DashboardCards, DashboardCard, DashboardCardsContainer };