import React from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { Eye, ChevronsRight } from 'react-feather';
import { formatNumber } from "../../utils";
import { TextMonoToken } from "../text";
import { BoxContainer } from "../box";
import { ErrorBoundary } from "../error";

const TransactionModalButton = ({ ...props }, urlTransactionHistory) => {

    const [dataSource] = React.useState(new DataManager({
        adaptor: new UrlAdaptor(),
        url: `${urlTransactionHistory}/${props.transaccionID}`
    }));
    const [showDialog, setShowDialog] = React.useState(false);

    const onClickEvent = (event) => {
        event.preventDefault();

        setShowDialog(true);
    };

    return (
        <>
            <button
                onClick={(e) => onClickEvent(e)}
            ><Eye className="h-3 w-3 text-gray-400" aria-hidden="true" /></button>

            {showDialog &&
                <DialogComponent
                    width='922px'
                    header={() => TransactionModalHeader({ ...props })}
                    content={() => TransactionModalContent({ ...props }, dataSource)}
                    isModal={true}
                    showCloseIcon={true}
                    closeOnEscape={true}
                    allowDragging={false}
                    visible={showDialog}
                    close={() => setShowDialog(false)}
                />
            }
        </>
    );
}

function TransactionModalHeader({ ...props }) {
    return (
        <div className="flex space-x-3">
            <div className="flex-shrink-0">
                <ChevronsRight className="h-10 w-10" aria-hidden="true" />
            </div>
            <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-gray-900">
                    {props.cliente}
                </p>
                <p className="text-sm text-gray-500">
                    {props.detalle}
                </p>
            </div>
        </div>
    );
}

function TransactionHistoryTable({ ...props }) {
    return (
        <ErrorBoundary>
            <BoxContainer>
                <GridComponent
                    allowPaging={false}
                    allowFiltering={false}
                    height={"auto"}
                    delayUpdate={false}
                    dataSource={props.dataSource}
                >
                    <ColumnsDirective>
                        <ColumnDirective
                            field='fecha'
                            headerText='Fecha'
                            clipMode="EllipsisWithTooltip"
                            textAlign='Left'
                            width={100}
                            type="dateTime"
                            format='dd-MM-yyyy HH:mm:ss'
                        />
                        <ColumnDirective
                            field='estado'
                            headerText='Estado'
                            textAlign='left'
                            clipMode="EllipsisWithTooltip"
                            width={200}
                            template={(data) => <><div>{data.estado}</div><small>{data.observacion}</small></>}
                        />

                    </ColumnsDirective>
                </GridComponent>
            </BoxContainer>
        </ErrorBoundary>
    );
}

function TransactionModalContent({ ...props }, dataSource) {

    return (
        <div className="columns-2 p-2">
            <div className="overflow-hidden">
                {/* detalle transaccion */}
                <div className="mr-2">
                    <dl className="detalles-transaccion">
                        <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-xs font-medium text-gray-500">Fecha</dt>
                            <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.fecha.toLocaleString('es-CL')}</dd>
                        </div>
                        <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-xs font-medium text-gray-500">Token</dt>
                            <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">
                                <TextMonoToken>
                                    {props.transaccionID}
                                </TextMonoToken>
                            </dd>
                        </div>
                        <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-xs font-medium text-gray-500">TrxID</dt>
                            <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.clienteTrxID}</dd>
                        </div>
                        <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-xs font-medium text-gray-500">Correlativo</dt>
                            <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.correlativo}</dd>
                        </div>
                        <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-xs font-medium text-gray-500">Medio de Pago</dt>
                            <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.medioPago}</dd>
                        </div>
                        <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-xs font-medium text-gray-500">Estado</dt>
                            <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.aprobada ? 'Aprobada' : 'Rechazada'}</dd>
                        </div>
                        {props.codigoAutorizacion &&
                            <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-xs font-medium text-gray-500">Código Autorización</dt>
                                <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.codigoAutorizacion}</dd>
                            </div>
                        }
                        {props.finalTarjeta &&
                            <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-xs font-medium text-gray-500">Número Tarjeta</dt>
                                <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.finalTarjeta}</dd>
                            </div>
                        }
                        {props.tipoCuotas &&
                            <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-xs font-medium text-gray-500">Tipo Cuotas</dt>
                                <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.tipoCuotas}</dd>
                            </div>
                        }
                        {props.numeroCuotas > 0 &&
                            <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-xs font-medium text-gray-500">Cuotas</dt>
                                <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">{props.numeroCuotas}</dd>
                            </div>
                        }
                        <div className="px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-xs font-medium text-gray-500">Monto</dt>
                            <dd className="mt-1 text-xs text-black dark:text-gray-100 sm:col-span-2 sm:mt-0">

                                {formatNumber(props.monto, 2, "$", "", ",", ".")}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div>
                {/* historial transaccion */}
                <TransactionHistoryTable
                    dataSource={dataSource}
                />
            </div>
        </div>
    );
}

export { TransactionModalButton }