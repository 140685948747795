function HeaderTitle({ title }) {
  return (<header>
    <div className="mx-auto mt-1 mb-6">
      <h1 className="text-base font-semibold leading-tight tracking-tight text-gray-900 dark:text-gray-100">{title}</h1>
    </div>
  </header>);
}

function LayoutContainer(props) {
  return (
    <div className="py-10">
      {props.title &&
        <header className="px-4 sm:px-6 lg:px-8">
          <HeaderTitle title={props.title} />
        </header>
      }
      <main>
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          {props.children}
        </div>
      </main>
    </div>
  );
}

export { LayoutContainer, HeaderTitle }


