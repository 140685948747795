import React from "react";
import { BoxContainer } from "../box"
import { ErrorBoundary } from "../error"
import { Input, InputMono, InputNumeric } from "../input"
import { Label } from "../label"
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Button } from "../button";

function TransactionFinder({
    filter,
    clearFilter,
    isLoading,
    showCliente,
    dataSourceFinanciadores,
    dataSourceClientes
}) {

    const limitMaxDate = new Date(Date.now());
    const fieldsDropDownList = { text: 'text', value: 'value' };
    const fieldsDropDownListFinanciadores = { text: 'descripcion', value: 'financiadorID' };
    const fieldsDropDownListClientes = { text: 'descripcion', value: 'clienteID' };
    const estados = [{ value: 'true', text: 'Aprobada' }, { value: 'false', text: 'Rechazada' }];

    const [monthStart] = React.useState(new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString()));
    const [monthEnd] = React.useState(new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()));

    const [inputTrxId, setInputTrxId] = React.useState("");
    const [inputToken, setInputToken] = React.useState("");
    const [inputCodAut, setInputCodAut] = React.useState("");
    const [inputTarjeta, setInputTarjeta] = React.useState("");
    const [inputCorrelativo, setInputCorrelativo] = React.useState("");
    const [inputMonto, setInputMonto] = React.useState("");

    const [dateRangeRef, setDateRangeRef] = React.useState();
    const [estadoRef, setEstadoRef] = React.useState();
    const [financiadorRef, setFinanciadorRef] = React.useState();
    const [clienteRef, setClienteRef] = React.useState();

    React.useEffect(() => { 
        
        if (dateRangeRef) {
            dateRangeRef.value = [monthStart, monthEnd];
        }

    }, [dateRangeRef,monthStart, monthEnd]);

    const clearFilterForm = async (event) => {

        event.preventDefault();

        await clearFilter();

        setInputTrxId("");
        setInputToken("");
        setInputCodAut("");
        setInputTarjeta("");
        setInputCorrelativo("");
        setInputMonto("");

        financiadorRef.clear();

        if (showCliente) {
            clienteRef.clear();
        }
        
        dateRangeRef.value = [monthStart, monthEnd];
        estadoRef.value = 'true';
    };

    const buscar = async (event) => {

        event.preventDefault();

        const query = [];

        const dateRange = dateRangeRef.getSelectedRange();
        const startDate = dateRange.startDate === null ? undefined : dateRange.startDate;
        const endDate = dateRange.endDate === null ? undefined : dateRange.endDate;

        const financiadorID = financiadorRef.value;
        const estado = estadoRef.value;

        const inputs = [
            { field: 'clienteTrxID', operator: 'equal', value: inputTrxId },
            { field: 'transaccionID', operator: 'equal', value: inputToken },
            { field: 'codigoAutorizacion', operator: 'equal', value: inputCodAut },
            { field: 'finalTarjeta', operator: 'equal', value: inputTarjeta },
            { field: 'estado', operator: 'equal', value: estado },
            { field: 'medioPago', operator: 'equal', value: financiadorID },
            { field: 'correlativo', operator: 'equal', value: inputCorrelativo },
            { field: 'monto', operator: 'equal', value: inputMonto },
            { field: 'fechaInicial', operator: 'greaterthan', value: startDate },
            { field: 'fechaFinal', operator: 'lessthan', value: endDate }
        ];

        if (showCliente) {
            const clienteID = clienteRef.value;
            inputs.push({ field: 'clienteID', operator: 'equal', value: clienteID });
        }

        inputs.forEach((e) => {
            if (e.value && e.value !== '') {
                query.push(e);
            }
        });

        await filter(query);
    };

    return (
        <ErrorBoundary>
            <BoxContainer className="px-4 sm:px-6 pb-6">
                <form className="space-y-8 divide-y divide-gray-200 dark:divide-gray-600">
                    <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-600">
                        <div>
                            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-1 gap-y-6 gap-x-4">
                                {showCliente &&
                                    <div>
                                        <Label
                                            htmlFor="ClienteID"
                                        >
                                            Cliente
                                        </Label>
                                        <DropDownListComponent
                                            ref={r => setClienteRef(r)}
                                            id="ClienteID"
                                            name="ClienteID"
                                            fields={fieldsDropDownListClientes}
                                            dataSource={dataSourceClientes}
                                            showClearButton={true}
                                        />
                                    </div>
                                }
                                <div>
                                    <Label
                                        htmlFor="Token"
                                    >
                                        Token
                                    </Label>
                                    <InputMono
                                        type="text"
                                        name="Token"
                                        id="Token"
                                        value={inputToken}
                                        className="font-mono slashed-zero tabular-nums tracking-widest"
                                        onChange={(x) => setInputToken(x.target.value)}
                                    />
                                </div>
                                <div>
                                    <Label
                                        htmlFor="TrxID"
                                    >
                                        TrxID
                                    </Label>
                                    <Input
                                        type="text"
                                        name="ClienteTrxId"
                                        id="ClienteTrxId"
                                        value={inputTrxId}
                                        onChange={(x) => setInputTrxId(x.target.value)}
                                    />
                                </div>
                                <hr/>
                                <div>
                                    <Label
                                        htmlFor="Fecha"
                                    >
                                        Fecha
                                    </Label>
                                    <DateRangePickerComponent
                                        strictMode={true}
                                        ref={(r) => setDateRangeRef(r)}
                                        firstDayOfWeek={1}
                                        max={limitMaxDate}
                                        maxDays={31}
                                        openOnFocus={true}
                                        serverTimezoneOffset={"-0400"}
                                        name="Fecha"
                                        id="Fecha"
                                    />
                                </div>
                                <div>
                                    <Label
                                        htmlFor="Estado"
                                    >
                                        Estado
                                    </Label>
                                    <DropDownListComponent
                                        ref={r => setEstadoRef(r)}
                                        id="Estado"
                                        name="Estado"
                                        fields={fieldsDropDownList}
                                        dataSource={estados}
                                        showClearButton={true}
                                        value={'true'}
                                    />
                                </div>
                                <hr/>
                                <div>
                                    <Label
                                        htmlFor="Financiador"
                                    >
                                        Medio de Pago
                                    </Label>
                                    <DropDownListComponent
                                        ref={r => setFinanciadorRef(r)}
                                        id="Financiador"
                                        name="Financiador"
                                        fields={fieldsDropDownListFinanciadores}
                                        dataSource={dataSourceFinanciadores}
                                        showClearButton={true}
                                    />
                                </div>
                                <div>
                                    <Label
                                        htmlFor="Correlativo"
                                    >
                                        Correlativo
                                    </Label>
                                    <Input
                                        type="text"
                                        name="Correlativo"
                                        id="Correlativo"
                                        value={inputCorrelativo}
                                        onChange={(x) => setInputCorrelativo(x.target.value)}
                                    />
                                </div>
                                <div>
                                    <Label
                                        htmlFor="CodAut"
                                    >
                                        Código de Aut.
                                    </Label>
                                    <Input
                                        type="text"
                                        name="CodAut"
                                        id="CodAut"
                                        value={inputCodAut}
                                        onChange={(x) => setInputCodAut(x.target.value)}
                                    />
                                </div>
                                <div>
                                    <Label
                                        htmlFor="Tarjeta"
                                    >
                                        Final Tarjeta
                                    </Label>
                                    <Input
                                        type="text"
                                        name="Tarjeta"
                                        id="Tarjeta"
                                        value={inputTarjeta}
                                        onChange={(x) => setInputTarjeta(x.target.value)}
                                    />
                                </div>
                                <div>
                                    <Label
                                        htmlFor="Monto"
                                    >
                                        Monto
                                    </Label>
                                    <InputNumeric

                                        name="Monto"
                                        id="Monto"
                                        min={0}
                                        format='c2'
                                        validateDecimalOnType={true}
                                        decimals={2}
                                        showSpinButton={false}
                                        onChange={(x) => setInputMonto(x.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="pt-5">
                                <div className="flex justify-end">
                                    {!isLoading && <>
                                        <Button
                                            onClick={clearFilterForm}
                                            loadingText={"Limpiando.."}>
                                            Limpiar
                                        </Button>
                                        <Button
                                            onClick={buscar}
                                            loadingText={"Buscando.."}>
                                            Buscar
                                        </Button>
                                    </>}
                                    {isLoading && <>
                                        <Button
                                            onClick={clearFilterForm}>
                                            ...cargando
                                        </Button>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </BoxContainer>
        </ErrorBoundary>)
}

export { TransactionFinder }