import { BackgroundTrianglify } from "../layout"

function HeaderBlack(props) {
    return (
        <div className="relative pb-20 bg-gray-800">
            <div className="absolute inset-0">
                <BackgroundTrianglify/>
                <div className="absolute inset-0 bg-white dark:bg-gray-600 mix-blend-multiply" aria-hidden="true" />
            </div>
            <div className="relative mx-auto py-10 px-4 sm:px-6 lg:px-8">
                <h1 className="text-white text-3xl">
                    <div className="absolute">
                        <props.icon className="h-8 w-8 text-gray-400" aria-hidden="true" />
                    </div>
                    <span className="ml-12">
                        {props.title}
                    </span>
                </h1>
                <p className="mt-5 text-xl text-gray-400">
                    {props.subtitle}
                </p>
            </div>
        </div>
    );
}

function HeaderBlackBottomSection(props) {
    return (
        <section
            className="-mt-24 mx-auto relative z-10 px-4 sm:px-6 lg:px-8">
            {props.children}
        </section>
    )
}

export { HeaderBlack, HeaderBlackBottomSection };