import { useLocation, useNavigate } from "react-router-dom";
import { TopMenu } from "../topMenu";
import auth from "../../services/auth";
import React from "react";
import { NombreEmpresa } from "../../data/datosLocalStorage";
import Menu from '../../data/menu.json';
import apiConfig from "../../data/config";

const CheckPermissions = (userRole, menuItem) => {

  let notAllowed = (((menuItem.roles && menuItem.roles.length > 0) && (!userRole || !menuItem.roles.includes(userRole.role))) // Roles permitidos usuario
    || ((menuItem.rolesPP && menuItem.rolesPP.length > 0) && (!userRole || !menuItem.rolesPP.includes(userRole.rolePP))) // Roles permitidos PP
    || ((menuItem.filterRolesPP && menuItem.filterRolesPP.length > 0) && (!userRole || menuItem.filterRolesPP.includes(userRole.rolePP)))); // Roles no permitidos PP

  return !notAllowed;
}

const TopMenuItemIsActive = (props, pathName) => {

  const url = props.url;

  let isActive = pathName === "/"
    ? url.startsWith(pathName)
    : url === pathName;

  if (isActive) {
    return isActive;
  }

  if (props.items !== undefined) {
    props.items.forEach(e => {
      if (!isActive)
        isActive = TopMenuItemIsActive(e, pathName);
      else return;
    });
  }

  return isActive;
}

const ProcessMenuItem = (menuItem, userRole, pathName) => {

  let newMenuItem = { ...menuItem };

  newMenuItem.active = TopMenuItemIsActive(newMenuItem, pathName);
  newMenuItem.allowed = CheckPermissions(userRole, newMenuItem);

  if (newMenuItem.items !== undefined) {
    newMenuItem.items = newMenuItem.items.map((item, i) => {
      item.active = TopMenuItemIsActive(item, pathName);
      item.allowed = CheckPermissions(userRole, item);
      return item;
    });
  }

  return newMenuItem;
}

function LayoutSecure(props) {

  let navigate = useNavigate();

  const [nombreEmpresa] = React.useState(NombreEmpresa);
  const [userRole, setUserRole] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [menu, setMenu] = React.useState([]);
  const location = useLocation();
  let pathName = location.pathname;

  React.useEffect(() => {
    const authRole = auth();
    setUserRole({ role: authRole.role, rolePP: authRole.rolePP });
    setLoading(false);
  }, []);

  React.useEffect(() => {

    async function checkUserStatus() {
      try {
        const response = await fetch(`${apiConfig.baseUrl}cuenta/status`, { method: 'GET' });
        if (response.ok) {
          const result = await response.json();
          if (result) {
            if (result.status === false) {
              navigate("/login", {});
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (!loading && (!userRole || nombreEmpresa === null)) {
      navigate("/login", {});
    }
    if (userRole) {

      if (!loading) {
        checkUserStatus();
      }

      let m = Menu.map((menuItem) => ProcessMenuItem(menuItem, userRole, pathName));

      setMenu(m);

      let filterMenu = m.filter((item) => {

        if (item.items !== undefined) {
          let filterSubMenu = item.items.filter((sItem) => {
            return sItem.allowed === false && sItem.active === true;
          });
          if (filterSubMenu.length > 0) {
            navigate("/login", {});
          }
        }
        return item.allowed === false && item.active === true;
      });
      if (filterMenu.length > 0) {
        navigate("/login", {});
      }

      setMenu(m);
    }
  }, [loading, userRole, navigate, nombreEmpresa, pathName]);

  return (
    <div className="min-h-full">
      {!loading &&
        <TopMenu menu={menu} userRole={userRole} />}
      {props.children}
    </div>
  );
}

export { LayoutSecure }