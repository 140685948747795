function Label({ ...props }) {
    return (
        <label
            {...props}
            className="block text-sm font-medium text-gray-700 dark:text-gray-200"
        >
            {props.children}
        </label>
    );
}

export { Label };