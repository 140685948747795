import React from "react";
import { LayoutContainer } from "../components/layout";
import { Transactions } from "../components/transacciones";
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import apiConfig from "../data/config";

function Transacciones() {
    
    const urlTransactionHistory = `${apiConfig.baseUrl}cliente/GetTransactionHistory`;
    const [dataSource] = React.useState(new DataManager({
        adaptor: new UrlAdaptor(),
        url: `${apiConfig.baseUrl}cliente/transactions`
    }));
    
    const [dataSourceFinanciadores] = React.useState(new DataManager({
        adaptor: new UrlAdaptor(),
        url: `${apiConfig.baseUrl}cliente/ListadoFinanciadores`,
        enableCaching: true
    }));

    return (<>
        <LayoutContainer
            title="Listado de Transacciones"
        >
            <Transactions
                dataSource={dataSource}
                dataSourceFinanciadores={dataSourceFinanciadores}
                urlTransactionHistory={urlTransactionHistory}
            />
        </LayoutContainer>
    </>)
}

export default Transacciones;